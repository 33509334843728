import Vue from 'vue'

Vue.mixin({
	methods: { 
		real: str => str.toLocaleString('pt-BR', {
			style: 'currency',
			currency: 'BRL'
		}),
		modalTitle: str => {
			let url = window.location.href.split('/')[3]
			return str == 'New' ? url == 'categories' ? 'labels.new_f' : 'labels.new' : 'labels.edit'
		},
		slugify: str => {
			str = str.replace(/^\s+|\s+$/g, '')
			str = str.toLowerCase()

			// remove accents, swap ñ for n, etc
			var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
			var to = 'aaaaeeeeiiiioooouuuunc------'
			for (var i = 0, l = from.length; i < l; i++) {
				str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
			}

			str = str
				.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
				.replace(/\s+/g, '-') // collapse whitespace and replace by -
				.replace(/-+/g, '-') // collapse dashes

			return str
		},
		passwordGenerator () {
			var length = 8,
				charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%&*-_',
				password = ''
			for (var i = 0, n = charset.length; i < length; ++i) {
				password += charset.charAt(Math.floor(Math.random() * n))
			}
			return password
		},
		readFile (file) {
			return new Promise(resolve => {
				const reader = new FileReader()
				reader.addEventListener('load', () => resolve(reader.result), false)
				reader.readAsDataURL(file)
			})
		},
		defaultColors () {
			return ['#feca57', '#ff9f43', '#ee5253', '#f368e0', '#b53471', '#7202f8', '#10ac84', '#01a3a4', '#0abde3', '#2e86de', '#341f97', '#dbdbdb', '#8395a7', '#576574', '#222f3e']
		},
		defaultToolbar () {
			return [
				[{ header: [2, 3, 4, 5, false] }],
				[{ 'align': [] }],
				['bold', 'italic', 'underline', 'strike'], 
				// [{ 'script': 'sub'}, { 'script': 'super' }],
				[{ color: [] }, { background: [] }], 
				[
					{ list: 'ordered' }, 
					{ list: 'bullet' }
				], 
				['link', 'image'], // 'code-block'
				// [{ size: ['small', false, 'large'] }],
				['clean']
			]
		}
	}
})
